#root {
    // height: 100vh;
    // overflow: hidden;
}

.searchPageContainer {
    overflow: auto;
    height: 100vh;
    z-index: 1;
    position: relative;
}

.responsiveContainer {
    max-width: 1400px;
    margin: auto;
}

.ReactModal__Body--open #root {
    overflow: hidden;

    .sidebar {
        z-index: 6 !important;
    }

    .sidebarOverlay {
        z-index: 5 !important;
    }
}

.captcha {
    overflow-x: auto;
    overflow-y: hidden;}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.maxHnone {
    max-height: none;
}

.App-link {
    color: #61dafb;
}

.dropdown.absolutePositioned {
    .contentContainer {
        position: absolute;
        width: 100%;
        // margin-top: -25px;

        // bottom: -75px;
    }
}


.contentContainer {
    @media (max-width: 575px) {
        padding: 0 0 73px;
    }
}

// .contentContainer > .container-fluid {
//     overflow: auto;
// }

.overflowHiddenOff {
    overflow: initial !important;
}

.customContainer {
    max-width: 1400px;
    margin: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    // margin-top: 32px;
    // overflow: hidden;

    // @media (max-width: 575px) {
    // }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sidebarContent {
    @media (max-width: 575px) {
        padding-bottom: 32px !important;
    }
}

.sidebarOverlay {
    z-index: 2 !important;
}

.sidebar {
    background-color: #fff;
    // left: 80px !important;
    width: 90%;
    max-width: 968px;
    background-color: #FFFFFF;
    box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);
    padding: 0 85px 100px;
    z-index: 3 !important;

    @media (max-width: 991px) {
        padding: 0 32px 32px;
    }
    // & > div {
    //   position: relative;
    //   // left: 80px;
    //   left: 100%;
    //   background-color: white;
    //   background-color: wheat;
    //   height: 100vh;
    //   width: 80px;
    //   padding-top: 56px;
    // }
    .inputWrapper {
        margin-top: 32px;
    }
}

.hamburgerContainer {
    background-color: white;
    left: 0;
    position: absolute;
    width: 80px;
    padding: 0 16px;
    z-index: 3;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 575px) {
        flex-direction: row;
        position: absolute;
        left: 0;
        right: 0;
        height: 72px;
        bottom: 0;

        .links, .support {
            padding: 13px 10px;
            display: flex;
            flex: 1 1 210px;
            margin: auto;
            justify-content: space-between;
        }

        .hamburger {
            display: none;
        }

        .support {
            flex: 1 1 70px;
        }
    }
}

.hamburger {
    background-color: transparent;
    border: none;
    // height: 40px;
    // width: 192px;
    // border: 2px solid var(--main);
    // border-radius: 20px;
    // background-color: white;
    // transition: all 0.3s ease-out 0s;
    // margin: auto;
    // left: 20px;
    // right: 0;
    // top: 20px;
    // color: var(--main);
    // font-size: 16px;
    // font-weight: 600;
    // line-height: 20px;
    // text-align: center;
}

.links {
    padding-top: 100px;
    flex: 0;
    min-width: 48px;
    margin-top: auto;
    margin-bottom: auto;
}

.sidebarLink {
    display: block;
    height: 48px;
    line-height: 48px;
    width: 200px;
    border-radius: 8px;
    overflow: hidden;
    color: var(--main);
    font-size: 16px;
    letter-spacing: -0.2px;
    transition: all 0.3s ease-out 0s;
    padding: 0 12px;
    // text-align: left;
    &:hover {
        background-color: var(--grey-light);
        // svg .toggle {
        //     stroke: var(--main) !important;
        // }
    }
}

body {
    &.closed {
        .hamburger {
            // color: #fff;
            // height: 40px;
            // width: 40px;
            // border: 2px solid var(--main);
            // background-color: var(--main);
        }

        .sidebarLink {
            width: 48px;
            max-width: 48px;
            padding-left: 12px;
            padding-right: 12px;
            // text-align: center;
        }
    }

    &.opened {
        .hamburger {}
    }
}

.isPresentOnTheList:disabled {
    background-color: var(--main-light) !important;
}
