@import './config.scss';
@import '../assets/fonts/frutiger.css';


html {
  font-size: 10px;
}

h1 {
    font-family: 'Frutiger';
    font-size: 38px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    @media (max-width: 991px) {
        font-size: 24px;
    }
}

h2 {
    font-weight: normal;
    font-size: 28px;
    @media (max-width: 991px) {
        font-size: 20px;
    }
}

p {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    // text-align: center;
}

body {
  font-family: 'Frutiger', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
	color: var(--grey);
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.7rem;
  white-space: normal !important;
}
