/* Utilities */
.bgMain {
  background-color: var(--main);
}

.colorGreyLight {
  color: var(--grey-light);
}

.colorGrey {
  color: var(--grey);
}

.colorMain {
  color: var(--main);
}

.colorText {
    color: var(--text);
}

.colorGreen {
  color: var(--green-light);
}

.colorGreen2 {
  color: #489d54;
}


.colorRed {
    color: var(--red);
}

.bgGreen {
    background-color: var(--green-light);
}

.font-16 {
  font-size: 1.6rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-14 {
  font-size: 1.4rem !important;
  // letter-spacing: -0.2px;
  line-height: 1.4rem !important;
}

.font-weight-semi-bold {
    font-weight: 500 !important;
}

.pointer {
  cursor: pointer;
}

.font-500 {
  font-weight: 500;
}

.iconWrapper {
  display: inline-block;
  width: 20px;
  height: 20px;

  &.large {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  &.small {
    width: 14px;
    height: 14px;
    line-height: 14px;
  }

  img, svg {
    max-width: 100%;
    width: auto;
    height: 100%;
  }
}

.p-relative {
  position: relative;
}


.nonButton {
  background-color: transparent;
  border: none;
}

.mt-40 {
  margin-top: 40px;
}
