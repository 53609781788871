@import '../../styles/config.scss';

.productRow.large {
    .imageContainer.large {
        height: 466px;
        position: relative;
    }

    .breaker {
        margin-top: 100px;
        margin-bottom: 100px;
        // margin: 100px auto;
        border-bottom: 1px solid var(--grey);

        @media (max-width: 991px) {
            margin: 50px 0;
        }

        &.small {
            margin-bottom: 8px;
            margin-top: 0;
        }

    }

    margin-top: 100px;
    @media (min-width: 992px) {
        .col-lg-6.noPaddingOnMobile {
            max-width: calc(50% - 50px);
            margin-left: auto;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .imageContainer {
        text-align: left;
    }
    .tableRow {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--grey);
        p {
            flex: 1 50%;
            font-family: Frutiger;
            font-size: 16px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #2e2e2e;
            margin: 6px 0;
        }
    }

    .addToListButton {
        max-width: calc(50% - 16px);
        margin-right: 16px;
        margin-bottom: 0 !important;
        padding: 7px;
        min-width: 210px;

        @media (max-width: 991px) {
            max-width: 100%;
        }

        &:hover {
            text-decoration: none;
        }

    }
}




@media (max-width: 991px) {
    .noPaddingOnMobile {
        padding: 0 !important;
    }
}

.rightColLarge {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 90px !important;
    @media (max-width: 991px) {
        padding: 0 !important;
    }
}

.imageBorder {
    border: solid 1px var(--grey);
}

.plusWrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    // border: 1px solid var(--grey-light);
    padding: 15px;

    &.semiTransparent {
        opacity: 0.6;
        background-color: #ffffff;
    }
}

.yourLocation {
    position: absolute;
    left: calc(45% - 550px);
    bottom: 68px;
    color: black;
    line-height: 30px;
    font-size: 16px;
    // background-color: white;
    // border-radius: 5px;
    padding: 16px 38px 16px 58px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 22px;

    @media (max-width: 1300px) {
        left: calc(43% - 505px);
        bottom: calc(7vw + 28px);
    }

    @media (max-width: 1175px) {
        left: 35px;
        bottom: 65px;
    }

    @media (max-width: 1200px) {
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        border-radius: 0;
    }




    &:before {
        content: "";
        display: inline-block;
        height: 26px;
        width: 26px;
        background-color: var(--red);
        border-radius: 50%;
        position: absolute;
        left:18px;
    }
}
