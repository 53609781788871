@import '../../../styles/config.scss';

.inputSelectWrapper {
    position: absolute;
    cursor: pointer;
}

.inputSelectValue {
    padding: 15px 20px;
}

.hasValue {
    color: var(--main);
}

.clearIcon {
    display: block;
    position: absolute;
    right: 30px;
    top: 18px;
}

.inputSelectOptionsWrapper {
    position: absolute;
    left: 0;
    height: 0;
    top: calc(100% + 2px);
    // width: calc(100% - 20px);
    width: 100%;
    opacity: 0;
    background: var(--light);
    color: var(--dark);
    border: 1px solid var(--faint);
    transition: opacity 200ms;
    overflow-y: auto;
    padding: 8px 0;

	background-color: #F3F3F3;
	box-shadow: 0 2px 0 0 rgba(55,56,60,0.4);
}

.inputSelectWrapper.isOpen .inputSelectOptionsWrapper {
    opacity: 1;
    height: auto;
    max-height: 300px;
    z-index: 2;
    // background-color: var(--grey-light);
    // box-shadow: 0 2px 0 0 rgba(55,56,60,0.4);
}

.inputSelectArrow {
    position: absolute;
    right: 10px;
    right: 0;
    // top: 8px;
    top: 50%;
    // width: 100%;
    text-align: right;
    padding: 15px 5px;
    transform: translateY(-50%);
    transition: transform 200ms;

    // svg {
    //     transform: translateY(-50%);
    //     transition: transform 200ms;
    // }
}

.inputSelectWrapper.isOpen .inputSelectArrow {
    transform: translateY(-50%) rotate(180deg);
}

.inputSelectOption {
    position: relative;
    height: 48px;
    // width: 266px;
    color: var(--main);
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 1.6rem;
    padding: 16px;
}

.inputSelectOption:hover {
    color: var(--main);
}

.relative {
    .inputSelectOptionsWrapper {
        position: relative;
        top: 0;
    }
    &.inputSelectWrapper {
        position: relative;
        &.isOpen .inputSelectArrow {
            transform: translateY(0) rotate(180deg);
        }
    }
    .inputSelectArrow {
        top: 5px;
        transform: initial;
    }
}
