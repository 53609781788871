@import '../../styles/config.scss';


.priceContainer {
    display: flex;
    /* align-content: space-around; */
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .inlineW50 {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .inlineW40 {
        width: 40%;
    }
    .inlineW60 {
        width: 60%;
    }
}

.addToListButton {
    max-height: 65px;
    max-width: 150px;
    @media (max-width: 767px) {
        max-width: 100%;
    }
    // float: right;
}


.imageContainer {
    height: 110px;
    text-align: right;


    @media (max-width: 767px) {
        text-align: center;
    }

    &.large {
        height: 214px;

        img {
            width: 100%;
            object-fit: cover;
            max-height: 100%;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
    }
}

.middleCol {
    padding: 34px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
}


.rightCol {
    padding: 34px 0;
    @media (max-width: 991px) {
        padding: 34px 0 16px;
        margin-left: 15px;
        padding-right: 0 !important;
    }
    @media (max-width: 767px) {
        margin-left: 0;
        padding: 8px 0 16px;
        padding-right: 16px !important;
    }
}

.leftCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 !important;
    @media (max-width: 991px) {
        padding: 0 16px;
        height: auto;
        margin-left: -15px;
    }
    @media (max-width: 767px) {
        margin-left: 0;
    }
}

.onPictureMobile {
    @media (max-width: 991px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: absolute;
        left: 0;
        bottom: 0;
        color: white;
        width: 100%;
        padding: 0 32px;

        p {
            flex: 1 50%;
            font-weight: 600;
        }
    }

}


.icon {
	flex: 0 0 30px;
}

.productRow {
	display: flex;
	width: 100%;
    // max-width: 800px;
    background-color: #ffffff;
    padding: 0 !important;

    @media (max-width: 991px) {
        padding: 0 16px;
    }

    @media (max-width: 767px) {

        .row {
            // width: auto !important;
        }
    }


    .row {
        width: 100%;
        margin-left: 0;
        @media (max-width: 991px) {
            // margin-left: -15px;
            // width: auto;
        }
    }

}

.productRow:not(.large) {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    min-height: 280px;

    @media (max-width: 991px) {
        height: auto;
        padding: 0 16px !important;
    }
    @media (max-width: 767px) {
        padding: 0 !important;
    }
}
