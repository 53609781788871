@import './bootstrap-reboot.css';
@import './typography.scss';
@import './config.scss';
@import './bootstrap.css';
@import './utilities.scss';

body {
	margin: 0;

	.sidebarLink {
		color: var(--main-light);
	}

	&.backdrop {
		.contentContainer {
			&:before {
				content: "";
				background-color: var(--grey-light);
				width: 100%;
				height: 100%;
				z-index: 1;
				position: absolute;
				opacity: 0.5;
				top: 0;
				left: 0;
			}
		}
	}
    &.loading {
        .productList {
            opacity: 0;
        }
    }

    &.ReactModal__Body--open {
        .spinnerLoading {
            position: fixed;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
        }
    }
}

.card {
	border-radius: 8px;
	background-color: #FFFFFF;
	box-shadow: 0 18px 60px -30px rgba(38,50,56,0.2);
	padding: 3.2rem;
	width: 100%;
	max-width: 397px;
}

.dropdown input.textMaterial {
	// padding: 5px 10px;
	cursor: pointer;
}

.bgGreenLight {
	background-color: var(--green-light) !important;
}

.invertedRed {
	border-color: var(--red-light) !important;
	color: var(--red-light) !important;
}

.ReactModal__Overlay {
	z-index: 3;
	background-color: white !important;
}


.ReactModal__Content {
    padding: 0 !important;
}

.floatRight {
    float: right;
}

.strokeWhite {
  .toggle {
    stroke: white;
  }
}

.strokeGrey {
  .toggle {
    stroke: var(--grey);
  }
}

.fillWhite {
  .toggle {
    fill: white;
  }
}

// why it doesnt work ?
.fillMain {
    fill: var(--main)!important;
    .toggle {
        stroke: var(--main)!important;
    }
}

.strokMain {
    stroke: var(--main)!important;
    .toggle {
        stroke: var(--main)!important;
    }
}

.arrow {
	display: block;
	position: absolute;
	left: 0;
    // right: 0;
	top: 0;
    bottom: 0;

	&.rotated {
		transform: rotate(180deg);
	}

	&.left {
		transform: rotate(90deg);
	}

	svg {
		width: 10px;
		height: auto;
        stroke: var(--main)!important;
        .toggle {
            stroke: var(--main)!important;
        }
	}
}

.mdc-tab:focus {
    outline: 0
}

.rc-slider-rail {
    background-color: var(--main-light);
}

.rc-slider-track {
    background-color: var(--main);
}

.rc-slider-handle {
    background-color: var(--main);
    border-color: var(--main);
}

.rc-slider-handle-click-focused:focus {
    // background-color: var(--main-light);
    border-color: var(--main);
}

.rc-slider-handle {
    &:hover {
        border-color: var(--main);
    }
    &:active {
        border-color: var(--main);
        box-shadow: 0 0 0 5px var(--main);
    }
}

.styledScrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.styledScrollbar::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

.styledScrollbar::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--main);
}

.print-only {
	display: none;
}
//
// ::-webkit-scrollbar-track {
//     background-color: var(--main-light);
//     background-color: #F5F5F5;
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//     border-radius: 10px;
// }
//
// ::-webkit-scrollbar {
//     width: 10px;
//     background-color: var(--main);
// }
//
// ::-webkit-scrollbar-thumb {
// 	border-radius: 10px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
//     background-color: var(--main);
// }
