@font-face {
    font-family: 'Frutiger';
    src: url("./af97a512-f55a-4f61-b2ce-659c883930fc.eot");
    src: url("./af97a512-f55a-4f61-b2ce-659c883930fc.eot?#iefix") format('embedded-opentype'),
         url("./3fbbd6b1-cfa7-4ff0-97ea-af1b2c489f15.woff") format('woff'),
         url("./076311f8-a01b-4389-a5eb-334a62e62d45.ttf") format('truetype'),
         url("./c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877.svg#c59a36ab-4ef8-4ee1-a2f8-48aa79d4f877") format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Frutiger';
    src: url("./fbd7c0fb-f004-4e83-81d2-1d400413a873.eot");
    src: url("./fbd7c0fb-f004-4e83-81d2-1d400413a873.eot?#iefix") format('embedded-opentype'),
         url("./9aa32a81-1124-4c43-b3db-15bfb1f7aed2.woff") format('woff'),
         url("./6faffbf4-f8e8-4817-b24b-a390e166be7e.ttf") format('truetype'),
         url("./fc09de64-de25-425e-90dc-a0cae29b02c4.svg#fc09de64-de25-425e-90dc-a0cae29b02c4") format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger';
    src: url("./7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot");
    src: url("./7d3671fc-8c26-459c-98e1-997c4f6c8c93.eot?#iefix") format('embedded-opentype'),
         url("./2a004a53-ac5c-43b3-9eeb-9f74ae4c1609.woff") format('woff'),
         url("./74d53f3b-1683-4d5a-a556-e13f6553cdf0.ttf") format('truetype'),
         url("./3f5a5b87-e71e-4544-be0c-da4daa132710.svg#3f5a5b87-e71e-4544-be0c-da4daa132710") format('svg');
    font-weight: 300;
    font-style: normal;
}
