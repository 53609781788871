.headerContainer {
    background-color: #fff;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(175,175,177,0.16);
    // padding-left: 80px;
    position: relative;
    padding: 0 16px;
    // display: flex;

    // z-index: 2;

    // @media (max-width: 575px) {
    //     max-height: 66px;
    //     overflow: hidden;
    // }




    .responsiveContainer {
        z-index: 1;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        // height: 100%;
        // padding-top: 30px;

        .headerTitle {
            // margin-top: -30px;
        }
    }
}
.headerContainer:not(.productPage) {
    background-image: url('../../assets/imgs/header.jpg');
    background-size: cover;
    height: 495px;
    background-position-x: 55%;
    background-position-y: 30%;

    &:after {
        content: "";
        display: block;
        pointer-events: none;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 640px) {
        background-image: url('../../assets/imgs/header2x.jpg');
        height: 307px;
        background-size: cover;
        background-position-y: 50%;
    }

    @media (max-width: 575px) {
        padding: 0 21px;
        // height: 520px;
    }
}

.dropdownLink {
    margin-left: 50px;
    max-height: 97px;
    position: relative;
    @media (max-width: 767px) {
        margin-left: 30px;
    }
}

.languageDropdown {
    width: 42px;
    margin: 24px 0 24px 24px;
    @media (max-width: 575px) {
        margin-top: 16px;
    }

}



.langCode {
    display: block;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #3d3d3d;
    color: #8c8c8c;
    background-color: #2e2d2d;
    font-size: 13px;
    text-transform: uppercase;
    // background: none;
    padding: 4px 10px 2px;
    display: block;

    &:hover {
        background: #343232;
        color: #fff
    }
}


.productPage {
    .headerLink, button {
        color: var(--text) !important;

        &:hover {
            color: var(--text) !important;
        }

        div {
            border-color: var(--text) !important;
        }
    }

    .responsiveContainer {
        border-bottom: 1px solid var(--grey);
    }
}

.headerLink {
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    // line-height: 80px;
    margin: 30px 0 0!important;
    padding-left: 16px;
    height: auto;
    z-index: 2;
    // margin-left: 50px;
    min-width: 100px;
    margin-left: auto;

    &:hover {
        text-decoration: none;
        color: white;
    }

    @media (max-width: 575px) {
        margin-top: 22px !important;
    }
}

.headerChildren {
    color: white;
    margin: auto;
    margin-top: -25px;

    h1 {
        color: white;
    }

    @media (max-width: 991px) {
        margin-top: 50px;
    }
}

.headerTitle {
    // color: var(--main);
    font-size: 18px;
    font-weight: 600;
    line-height: 80px;
    margin-left: 16px;
    // flex: 0 0 100px;

    @media (max-width: 991px) {

        // width: 100%;
        // padding: 0 16px;
    }

    @media (max-width: 575px) {
        padding: 0;
        img {
            max-width: 100px;
            max-height: 26px;
            margin-top: 22px;
            vertical-align: top;
        }
        // margin-left: 0;
        // width: 100%;
        // padding: 0 16px;
    }

    @media (max-width: 475px) {
        margin-left: 0;
        // width: 100%;
        padding: 0;
        // padding: 0 16px;
    }
}

.headerContent {
    flex: 1 1;
    display: flex;
    /* align-content: baseline; */
    justify-content: flex-end;
    // padding-right: 50px;
    flex-wrap: wrap;
    // height: 38px;
    // @media (max-width: 767px) {
        // margin-left: -100px;
    // }
    // padding: 24px 0;

    @media (max-width: 475px) {
        padding-right: 0;
        .headerLink {
            // margin-left: 36px;
        }
    }
    // @media (max-width: 575px) {
    //     max-width: 245px;
    //     margin-left: auto;
    //     // width: 100%
    // }
}

.headerAvatar {
    position: absolute;
    right: 3.2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 32px;
    width: 32px;

    img {
        width: 100%;
    }
}

.username {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 16px;
}

.email {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 16px;
    margin-bottom: 30px;
}
