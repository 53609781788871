@import '../../styles/config.scss';

.button {
    display: inline-block;
    position: relative;
    text-align: center;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    // margin-top: 4.5rem;


    font-family: 'Frutiger';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    max-height: 52px;
    // color: #e60000;
}

.button:focus,
.button:focus {
    outline: 0;
}

.mask {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(253,243,242, 0.5);
    transform-origin: 0 100%;
    transform: scaleY(0);
    transition: 200ms transform;
}
/* Mask (Hover, Active) */
.button:hover .mask,
.button:hover .mask {
    transform: scaleY(1);
}
/* Pill */
.button.pill {
    color: var(--text);
    background: var(--main-light);
    padding: 14px 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    font-weight: 300;
    // height: 56px;
    // border: 2px solid var(--main);
    // border-radius: 28px;

    border-radius: 5px;
    // background-color: #e60000;

}

.buttonChild {
    position: relative;
    width: 100%;
    display: block;
    z-index: 1;
}

.button.invertedPill {
    line-height: 26px;
    font-size: 18px;
    // border-radius: 10px;
    padding: 7px 0 7px;
    color: var(--main);
    border: 2px solid var(--main);
    background: var(--light);
    border-radius: 5px;


    &:disabled {
        background-color: var(--main-light) !important;
        cursor: not-allowed;
    }
    // width: 150px;
    // height: 43.2px;
    // border: solid 2px ;
}

.button.small {
    // width: 200px;
}

.button.medium {
    // width: 270px;
}

.button {
    &.fullWidth {
        width: 100%;
        // padding: 14px 32px;
        padding: 20px 32px;
    }

    &.fullWidthInverted {
        width: 100%;
        padding: 20px 32px;
        color: var(--main);
        border: 1px solid var(--main);
        background: transparent;
    }
}
/* Link */
.basicLink {
    color: var(--main);
    font-family: Frutiger;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 1.5rem;

    height: 20px;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e60000;

    &:hover {
        opacity: 0.7;
    }
}

.basicLink .mask {
    display: none;
}
