.checkboxContainer {
    max-height: 150px;
    overflow: auto;
    padding: 10px 0 8px;
    // padding: 12px 12px 10px;
    // border: 1px solid var(--grey);
}

.formContainer {
    margin-bottom: 32px;

    .formTitle {
        font-size: 20px;
        margin-bottom: 16px;

        &.bordered {
            border-bottom: 1px solid var(--grey);
            padding-bottom: 16px;
        }
    }
}


.scrollToLoadMore {
    margin: 0 0 200px;
    text-align: center;
    font-size: 32px;
    width: 100%;
}

.angebotRow {
    display: flex;
    max-height: 100px;
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 2;
    padding: 16px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);

    @media (max-width: 767px) {
        padding: 16px 0 16px;

        & > div {
            flex-direction: column;
        }
    }

    @media (max-width: 991px) {
        display: block;
    }
}

.yourListButton {
    max-width: 270px !important;
    text-align: left;
    padding: 15px;
    background-color: var(--main-light);
    border-radius: 5px;
    @media (max-width: 991px) {
        float: right;
        max-width: none !important;
    }

    .buttonChild {
        padding-right: 22px;
    }
}

.breaker {
    border-bottom: 1px solid var(--grey);
    // margin: 32px 0;
}

.showingResultsNo {
    max-width: 243px;
    height: 40px;
    // font-family: Frutiger;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b3344;
    line-height: 56px;
    // margin-left: 20px;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 30px;
    }
}

.headerText {
    max-width: 1169px;
    margin: 100px auto auto;
    // height: 57px;
    font-family: 'Frutiger';
    // font-size: 38px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: normal;
    text-align: center;
    color: #2e2e2e;
    @media (max-width: 991px) {
        margin: 80px 0 0;
        // margin-bottom: 32px;
    }
}

.disclaimer {
    // position: absolute;
    // bottom: -1;
    // left: 15px;
}

.jumboText {
    margin-top: -41px;
    margin-bottom: 100px;

    @media (max-width: 991px) {
        margin: -50px 0 50px;
        // margin-bottom: 32px;
    }
}

// Remove scroll when sidebar opened
// .searchPageContainer {
//     overflow: auto;
//     height: 100vh;
// }
// #root {
//     overflow: hidden;
// }

.backToSearchButton {
    font-size: 18px;
    font-weight: normal;

    .arrow {
        left: -12px;
        bottom: 3px;
    }
}

.filterTitle {
    max-width: 302px;
    // text-transform: uppercase;
    // height: 39px;
    font-family: 'Frutiger';
    // font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e2e;
    font-size: 20px;
    margin-top: 35px;
}

.clearAllButton {
    margin: 22px 0 8px;
    height: auto;
}

.filters {
    // width: 271px;
    // height: 1726px;
}

.formContainer {
    border: solid 1px #e5e5e5;
    padding: 18px 30px;
    margin-bottom: 0;
    border-bottom: solid 1px #e5e5e5;
}

.visible {
    visibility: visible !important;
}

.headerParagraph {
    max-width: 570px;
    margin: 12px auto auto;
    // height: 60px;
    text-align: center;
}

.filterCol {
    max-width: 302px !important;
    @media (min-width: 992px) {
        // position: sticky !important;
        left: 0;
        // top: 32px;
        // height: 1000px;
        overflow: auto;
        // height: calc(100vh - 0px);
        top: 0;
    }
    @media (max-width: 991px) {
        max-width: 100% !important;
    }
}

.numberOfProducts {
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
    right: 8px;
    height: 24px;
}

.finderPage {
    &.contentContainer {
        padding-top: 0;

        .customContainer {
            display: flex;
            margin-top: 28px;
            // margin-top: 33px;
            // padding: 33px 16px;
            // overflow: auto;
            // min-width: 1000px;
            justify-content: space-between;
            @media (max-width: 991px) {
                margin-top: 36px;
            }
        }
        @media (max-width: 767px) {
            .customContainer {
                padding-top: 0;
                margin-top: 0;
            }
        }
    }

    .headerRow {
        // padding: 0 32px;
        position: relative;
        width: 100%;
        display: flex;
        margin-bottom: 24px;
    }
}

.filters {
    max-width: 400px;
    transition: all ease-in-out 500ms;
    @media (max-width: 991px) {
        max-width: 100%;
        margin: 32px 0;
        max-height: 0;
        // height: 0;
        overflow: hidden;

        &.expanded {
            // height: auto;
            max-height: 2000px;
        }
    }
    @media (max-width: 767px) {
        margin: 16px 0;
    }
}

.mt-48 {
    margin-top: 48px;
}

.entity {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 16px;
}

.applyFilters {
    height: 15px;
    width: 99px;
    color: var(--red-light);
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: right;
}

.dateString {
    height: 12px;
    width: 111px;
    color: var(--main);
    font-size: 10px;
    letter-spacing: -0.13px;
    line-height: 16px;
}

.buttonExplanation {
    height: 17px;
    width: 140px;
    color: var(--main);
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
}

.modalContent {
    width: 100%;
    margin: auto;
    max-width: 1400px;
    margin-bottom: 64px;
    // iPhone fix for empty buttons
    transform: translateZ(1px);

    h1 {
        // height: 73px;
        // font-family: Frutiger;
        // font-size: 28px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2e2e2e;
        margin-bottom: 32px;
        @media (max-width: 991px) {
            // margin-bottom: 32px;
        }
    }

    .modalSubHeader {
        margin-top: calc(24vh - 100px);
        display: flex;
        flex-direction: row;
        @media (max-width: 991px) {
            flex-direction: column;
            margin-bottom: 0;
            margin-top: 52px;
        }
        @media (max-width: 575px) {
            overflow-x: auto;
        }
    }

    .modalHeader {
        display: flex;
        // position: sticky;
        top: 25px;
        // background-color: var(--grey-light);
        @media (max-width: 991px) {
            overflow-x: auto;
            flex-direction: column;
        }

        .column {
            font-size: 1.6rem;

            &:first-of-type {
                font-weight: 600;
            }
        }
    }

    .title {
        font-weight: 500;
        line-height: 17px;
        margin-bottom: 42px;
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
}

.modalContentWarpper {
    border-radius: 8px;
    background-color: white;
    padding: 48px;
    @media (max-width: 991px) {
        padding: 16px;
    }
}

.modalNavigationContainer {
    display: flex;
    @media (max-width: 991px) {
        flex-wrap: wrap;

        .nextButton {
            order: -1;
        }
    }
}

.sliderWrapper {
    margin: 38px 0 20px;
}

.pill {
    min-height: 27px;
    // width: 95px;
    // height: 27px;
    opacity: 0.6;
    border-radius: 3px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    padding: 0 4px;
    color: var(--text) !important;
    margin-bottom: 16px;
    font-weight: 300;

    &.available {
        background-color: #d0f0d1;
    }

    &.availableSoon {
        width: auto;
        // max-width: 190px;
        // height: 27px;
        background-color: #e5e5e5;
    }

    svg {
        margin-top: -2px;
        margin-right: 5px;
    }
}

.col-12 p.labelReplacement {
    margin-top: 34px;
}

.showChildOnHover {
    .showable {
        visibility: hidden;
    }

    &:hover {
        .showable {
            visibility: visible;
        }
    }
}

.headerRow {
    @media (max-width: 767px) {
        display: none !important;
    }
}
