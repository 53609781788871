:root {
  --main: #e60000;
  --main-light: #fdf3f2;
  --grey: #e5e5e5;
  --grey-dark: #263238;
  --grey-light: #F3F3F3;
  --blue: #0020A2;
  --blue-light: #37383C;
  --red: #D22630;
  --red-light: #D78787;
  --green-light: #C4D787;
  --faint: #F5F5F5;

  --text: #2e2e2e;

  --dark: #000000;
  --light: #ffffff;

  --header-height: 80px;

  --mdc-theme-primary: #263238;
  // --title-font-family: 'Lato', 'arial', sans-serif;
  // --title-font-size: 34px;
  // --title-line-height: 40px;
  // --title-font-weight: 900;
  //
  // --subtitle-font-family: 'Lato Regular', 'arial', sans-serif;
  // --subtitle-font-size: 24px;
  // --subtitle-line-height: 28px;
  //
  // --formheading-font-family: 'Lato Regular', 'arial', sans-serif;
  // --formheading-font-size: 20px;
  // --formheading-line-height: 26px;
  //
  // --highlighttitle-font-family: 'Lato Regular', 'arial', sans-serif;
  // --highlighttitle-font-size: 16px;
  // --highlighttitle-line-height: 20px;
  // --highlighttitle-letter-spacing: 20px;
  //
  // --label-font-size: 15px;
  // --label-line-height: 20px;
  //
  // --checkbox-size: 20px;
  //
  // --small-text-size: 12px;
}
