.small {
    position: absolute !important;
    top: 22px !important;
    right: 15px !important;
    transform: scale(0.5) !important;
    height: 93px !important;
    width: 76px !important;
    left: auto!important;
    @media (max-width: 991px) {
        top: 78px !important;
    }
}

.relative {
    position: relative !important;
}


.loading {
    width: 100%;
    height: 100%;
    max-height: 0;
    // background-color: rgba(0,0,0,0.5);
    // position: fixed;
    position: sticky;
    left: 0;
    top: 100px;
    z-index: 1000;

    @media (max-width: 991px) {
        position: fixed;
    }

    p {
        position: relative;
        top: calc(50vh - 50px);
        text-align: center;
        color: white;
        font-size: 20px;
        white-space: pre;
    }

    .loader {
        svg {
            height: 120px;
            width: 120px;
            margin: auto;
            display: block;
            // red
            fill: rgba(231, 64, 33, 0.42);
            // fill: rgba(255, 255, 255, 0.42);
        }
    }

    .logo {
        position: absolute;
        left: 0;
        right: 0;
        top: 44px;
        margin: auto;
        width: 63px;
        text-align: center;

        img {
            width: 100%;
            max-width: 30px;
            margin: auto;
        }
        // svg {
        //   width: 100%;
        //   fill: rgba(231, 64, 33, 0.42);
        // }
    }

    .sticky {
        // top: calc(50vh - 100px);
        position: sticky !important;
        // position: relative;
        top: 0;
        left: 0;
    }

    .wrapper {
        width: 115px;
        margin: auto;
        position: fixed;
        left: 335px;
        right: 0;
        top: 0;
        bottom: 0;
        height: 150px;
        top: calc(50vh - 80px);
        @media (max-width: 991px) {
            left: 0 !important;
        }
    }
}
