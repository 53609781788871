@import '../../../styles/config.scss';

.inputText {
    display: block;
    width: calc(100% - 40px);
    border: none;
    padding: 15px 20px;
}

.inputText:focus {
    outline: none;
}

.textStandard {
    color: var(--dark);
    background: var(--light);
    // border-radius: 10px;
    width: 100%;

    height: 44px;
    border: solid 1px #e5e5e5;
    margin-bottom: 16px;
    font-size: 14px;
}
/* Material text input */
.textMaterial {
    padding: 5px 0;
    display: block;
    width: 100%;
    border: none;
    // border-bottom: 1px solid currentColor;
    background: transparent;
    color: var(--text);
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 16px;
    // box-shadow: 0 2px 0 0 var(--grey);
    border-bottom: 2px solid var(--grey);

    &:focus,
    &:hover {
        // box-shadow: 0 2px 0 0 #37383C;
        border-bottom: 2px solid #37383C;
    }

    &.hasValue {
        color: var(--text);
    }
}

.textMaterial + label {
    position: absolute;
    pointer-events: none;
    padding: 0;
    top: 19px;
    left: 0;
    // opacity: 0.8;
    transition: 200ms ease all;
    color: var(--grey);
    height: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    color: rgb(117, 117, 117);
    // height: 50px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 16px;
}

.textMaterial.hasValue + label,
.textMaterial:focus + label {
    top: -22px;
    left: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}
