@import '../../styles/config.scss';

.inputWrapper {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    // min-height: 56px;
    min-height: 30px;
    display: flex;
    width: auto;
    width: 100% !important;
    // margin-bottom: 15px;
    // margin-bottom: 3.2rem;
    &.high {
        margin-top: 5.6rem;
        @media (max-width: 575px) {
            margin-top: 3.6rem;
        }
    }

    &.w-100 {
        width: 100%;

        .dropdown {
            width: 100%;
        }
    }
}
