@import '../../../styles/config.scss';

.checkboxWrapper {
  list-style: none;
  padding: 0;
}

.checkbox {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  cursor: pointer;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
  width: 100%;
  white-space: pre;

  height: 26px;
  color:  var(--blue-light);
  // color: #37383C;
  font-size: 1.4rem;
  letter-spacing: -0.17px;
  line-height: 1.6rem;
  text-transform: capitalize;
}

.checkboxLabel input.checkboxInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkboxMark {
    border-radius: 3px;
    position: absolute;
    // background: url('../../../assets/icons/checkmark-slim.svg') center / contain no-repeat;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: 2px solid #2e2e2e;
}

:global(.filters) {
  .checkboxMark {
    position: absolute;
    // background: url('../../../assets/icons/checkmark-slim.svg') center / contain no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    height: 18px;
    width: 18px;
    margin: auto;
  }

  .checkboxLabel {
    font-size: 13px;
    flex: 1 50%;
  }

  :global(label.fullWidth) {
    flex: 1 100%;
    margin-top: 19px !important;
    white-space: normal;

    &:first-child {
      margin-top: 10px !important;
    }
  }
}

.checkboxLabel:hover input.checkboxInput ~ .checkboxMark {  }

.checkboxLabel input.checkboxInput:checked ~ .checkboxMark {
    background-color: var(--main);
    border: 2px solid var(--main);
    background-size: 10px;
    opacity: 1;

    &:after {
        content: "";
        display: block;
        background: url('../../../assets/icons/checkmark-bold-white.svg') center / contain no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.checkboxMark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxLabel input.checkboxInput:checked ~ .checkboxMark:after {
  display: block;
}
