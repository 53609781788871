@import '../../styles/config.scss';

.dropdownCollapse {
    // margin-right: 46px;
    // height: 100%;
    // height: 80px;
    // position: absolute;
    // right: 0;

    @media (max-width: 575px) {
        // width: 380px;
        // margin-right: 0;
        // max-width: 100vw;
    }
}

.collapseButton {
    white-space: pre-wrap !important;
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap !important;
    white-space: -o-pre-wrap !important;
    word-wrap: break-word !important;
    background-color: transparent !important;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    padding: 9px 0 8px !important;
    height: 100%;
    width: 100%;
    margin-left: auto;

    &:focus {
        box-shadow: none !important;
        border: none;
        outline: 0;
    }
}

.label {
    // margin: auto;
    // height: 20px;
    text-align: left;
}

.labelContainer {
    position: relative;
    // height: 50px;
    // height: 100%;
    // width: 125px;
    width: 100%;
    // color: var(--main-light);
    // color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    z-index: 9;
    @media (max-width: 575px) {
        margin-left: auto;
    }
}

.contentContainer {
    // position: absolute;
    // width: 356px;
    // box-shadow: 0 10px 30px -20px rgba(1, 50, 62, 0.3);
    // box-shadow: -10px 0 30px -20px rgba(1,50,62,0.3);
    // top: 44px;
    // right: 30px;
    z-index: 9;

    @media (max-width: 575px) {
        right: 0;
        width: 100%;
    }
}

.arrow {
    height: 8px;
    width: 8px;
    // border-bottom: 1px solid #8c8c8c;
    // border-right: 1px solid #8c8c8c;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    transition: 150ms transform;
    position: absolute;
    right: 10px;
    bottom: 0;
    top: -7px;
    margin: auto;
}

.activeArrow {
    transform: rotate(-135deg);
}

.content {
    border-radius: 3px;
    // display: none;
    // background-color: #8c8c8c;
    max-height: 0;
    overflow: hidden;
    transition: 300ms ease max-height;

    &.isOpened {
        max-height: 500px;
        // display: block;
    }

    .paddingContainer {
        // padding: 12px;
    }
}
