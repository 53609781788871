@media print {
    .productRow {
        display: block !important;
    }

    .printBreakAfter {
        page-break-after: always;
    }

    .printBreakBefore {
        page-break-before: always;
    }

    .print-only {
        display: block !important;
    }

    .notPrint {
        display: none !important;
    }

    @page {
        margin: 0cm 0.5cm;
        // width: 900px;
        // width: 21cm;
        // height: 29.7cm;
        size: A4;
    }

    body {
        height: auto !important;
        #printHeader {
            display: none;
        }
        // * {
        //     overflow: auto !important;
        //     height: auto !important;
        //  }

        .productPage .responsiveContainer {
            border: none;
        }

         .modalContentWarpper {
             padding: 0;
         }

        &.ReactModal__Body--open {
            margin: 0;
            color: #000;
            background-color: #fff;

            .productRow.large {
                margin-top: 0;
            }

            .ReactModalPortal {
                height: 100% !important;
            }

            #root {
                display: none;
            }

            .ReactModal__Overlay, .ReactModal__Content {
                position: relative !important;
                z-index: 9999999;
                height: 100% !important;
            }

            .imageContainer.large {
                height: 6.5cm !important;
            }

            .col-lg-7 {
                flex: 0 0 55%;
                max-width: 55%;

                &.noPaddingOnMobile {
                    transform: scale(0.85);

                }
            }

            .col-lg-5 {
                flex: 0 0 45%;
                max-width: 45%;
            }

            .rightColLarge {
                transform: scale(0.85);
                padding: 0 0 !important;
            }

            .col-lg-6 {
                flex: 0 0 50%;
                max-width: 50%;
                margin-top: 0 !important;
            }



            .productRow > #page1 > .row {
                margin-bottom: -30px !important;
                & > .col-12 {
                    transform: scale(0.85);
                }
            }

            .breaker {
                margin: 20px 0 -20px;
            }

            .headerTitle img {
                margin-bottom: .2cm;
                display: block;
                // height: 1.5cm !important;
                transform: scale(2);
                margin-left: 1cm;
            }

            .imageContainer {
                text-align: left !important;
            }

            .headerTitle {
                width: 100%;
                display: block;
                border-bottom: 1px solid rgba(175,175,177,0.16);
                height: 70px;
                position: absolute;
                top: 0.5cm;
                // padding: 0 1cm;
                transform: scale(0.96);
                img {
                    height: 1.5cm;
                    width: auto;
                }
            }

            #page1 {
                height: 29cm !important;
                position: relative;
                display: block !important;
                padding-top: 2cm;
            }

            #page2 {
                height: 27cm !important;
                display: block !important;
                width: 100%;
                position: relative;
                padding-top: 2cm;

                .headerTitle {
                }

                .printFooter {
                    bottom: -2cm;
                }

                .printLocationImage {
                    display: block !important;
                    object-fit: contain;
                    transform: rotate(-90deg);
                    position: absolute;
                    top: 8cm;
                    right: -1cm;
                    max-width: 25cm;
                    height: auto;
                }
            }

            #page3 {
                height: 27cm !important;
                padding-top: 2cm;
                position: relative;
                display: block !important;
                & > img {
                    width: 100%;
                    height: auto;
                    margin-top: 1cm;
                    padding: 0 0.85cm;

                }
                .printFooter {
                    bottom: -1.5cm;
                }
            }

            .printFooter {
                position: absolute;
                bottom: 0cm;
                font-size: 1em !important;
                line-height: 1em !important;
                color: var(--text);
                word-break: break-all;
                transform: scale(0.9);
                a {
                    // margin-left: 2px;
                }
            }

        }

        .backToSearchButton, .yourListButton, .headerContent, .addToListButton, .plusWrapper {
            display: none;
        }
    }
}
