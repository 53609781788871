@import '../../../styles/config.scss';

.inputSelectOption {

    &:hover, &:focus, &.active {
        // font-weight: 900;
        // background-color: white;
        border: 1px solid var(--grey-dark)
    }
}
